/* General */

.calendar {
  /* max-width: 1000px; */
  margin: auto;
}

.calendar > * {
  min-height: 150px;
  border: .5px solid lightgray;
}

/* Weekends */
.calendar > *:nth-child(7n),
.calendar > *:nth-child(7n+1) {
  background-color: rgb(247, 247, 247);
}
